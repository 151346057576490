	/*
  	Flaticon icon font: Flaticon
  	Creation date: 23/02/2020 21:24
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
font-style: normal;
}

.flaticon-door:before { content: "\f100"; }
.flaticon-bedroom:before { content: "\f101"; }
.flaticon-plug:before { content: "\f102"; }
.flaticon-recycle-bin:before { content: "\f103"; }
.flaticon-stairs:before { content: "\f104"; }
.flaticon-work-table:before { content: "\f105"; }
.flaticon-wardrobe:before { content: "\f106"; }
.flaticon-chair:before { content: "\f107"; }
.flaticon-phone:before { content: "\f108"; }
.flaticon-key:before { content: "\f109"; }
.flaticon-mirror:before { content: "\f10a"; }
.flaticon-bathroom:before { content: "\f10b"; }
.flaticon-window:before { content: "\f10c"; }
.flaticon-television:before { content: "\f10d"; }
.flaticon-house:before { content: "\f10e"; }
.flaticon-clock:before { content: "\f10f"; }
.flaticon-armchair:before { content: "\f110"; }
.flaticon-baby-crib:before { content: "\f111"; }
.flaticon-curtain:before { content: "\f112"; }
.flaticon-lamp:before { content: "\f113"; }
.flaticon-chest:before { content: "\f114"; }
.flaticon-towels:before { content: "\f115"; }
.flaticon-garage:before { content: "\f116"; }
.flaticon-kitchen:before { content: "\f117"; }
.flaticon-pillow:before { content: "\f118"; }
.flaticon-ceiling-light:before { content: "\f119"; }
.flaticon-air-conditioner:before { content: "\f11a"; }
.flaticon-fan:before { content: "\f11b"; }
.flaticon-iron:before { content: "\f11c"; }
.flaticon-ironing-board:before { content: "\f11d"; }
.flaticon-microwave:before { content: "\f11e"; }
.flaticon-bucket:before { content: "\f11f"; }
.flaticon-sink:before { content: "\f120"; }
.flaticon-television-1:before { content: "\f121"; }
.flaticon-vacuum-cleaner:before { content: "\f122"; }
.flaticon-mailbox:before { content: "\f123"; }
.flaticon-library:before { content: "\f124"; }
.flaticon-dressing-table:before { content: "\f125"; }
.flaticon-alarm-clock:before { content: "\f126"; }
.flaticon-oven:before { content: "\f127"; }
.flaticon-picture:before { content: "\f128"; }
.flaticon-ruler:before { content: "\f129"; }
.flaticon-star:before { content: "\f12a"; }
.flaticon-event:before { content: "\f12b"; }
.flaticon-share:before { content: "\f12c"; }
.flaticon-writing:before { content: "\f12d"; }
.flaticon-home:before { content: "\f12e"; }
.flaticon-layers:before { content: "\f12f"; }
.flaticon-checklist:before { content: "\f130"; }
.flaticon-facebook:before { content: "\f131"; }
.flaticon-linkedin:before { content: "\f132"; }
.flaticon-instagram:before { content: "\f133"; }
.flaticon-twitter:before { content: "\f134"; }
.flaticon-blog:before { content: "\f135"; }
.flaticon-writing-1:before { content: "\f136"; }
.flaticon-list:before { content: "\f137"; }
.flaticon-headset:before { content: "\f138"; }
.flaticon-company-1:before { content: "\f139"; }
.flaticon-playstore:before { content: "\f13a"; }
.flaticon-apple:before { content: "\f13b"; }
.flaticon-search:before { content: "\f13c"; }
.flaticon-left-quote:before { content: "\f13d"; }
.flaticon-bone:before { content: "\f13e"; }
.flaticon-home-1:before { content: "\f13f"; }
.flaticon-eye:before { content: "\f140"; }
.flaticon-new:before { content: "\f141"; }
.flaticon-history:before { content: "\f142"; }
.flaticon-user:before { content: "\f143"; }
.flaticon-clipboard:before { content: "\f144"; }
.flaticon-bookmark:before { content: "\f145"; }
.flaticon-shut-down-button:before { content: "\f146"; }
.flaticon-sales-agent:before { content: "\f147"; }
.flaticon-company:before { content: "\f148"; }
.flaticon-sold:before { content: "\f149"; }
.flaticon-telephone:before { content: "\f14a"; }
.flaticon-location:before { content: "\f14b"; }
.flaticon-speech-bubble:before { content: "\f14c"; }
.flaticon-villa:before { content: "\f14d"; }
.flaticon-beach-house:before { content: "\f14e"; }
.flaticon-apartment:before { content: "\f14f"; }
